import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  button: {
    color: "white",
  },
  flag: {
    fontSize: 32,
    textShadow: "2px 2px 3px rgba(0, 0, 0, 0.3)",
    marginRight: 8,
    lineHeight: 1,
  },
  flagImage: {
    width: 32,
    marginRight: 8,
  },
}));
