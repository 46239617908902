import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { LinearProgress } from "@material-ui/core";
import React, { PropsWithChildren, Suspense } from "react";
import { useHistory } from "react-router-dom";
import LandingPage from "./Pages/LandingPage";
import { msalConfig } from "./utils/config";
import { CustomNavigationClient } from "./utils/NavigationClient";

const pca = new PublicClientApplication(msalConfig);

interface Props {}

const Auth = (props: PropsWithChildren<Props>) => {
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <Suspense fallback={<LinearProgress />}>
        <LandingPage>{props.children}</LandingPage>
      </Suspense>
    </MsalProvider>
  );
};

export default Auth;
