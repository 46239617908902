import React, { useState } from "react";
import Banner from "../Banner";
import SideMenu from "../SideMenu";
import useStyles from "./style";

type PageStructureProps = {
  children: any;
};

const PageStructure = ({ children }: PageStructureProps) => {
  const [, setIsDrawerOpen] = useState(true);
  const classes = useStyles();

  const toggleDrawer = () => {
    setIsDrawerOpen((state) => !state);
  };
  return (
    <div className={classes.root}>
      <Banner toggleDrawer={toggleDrawer} />
      <SideMenu />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div>{children}</div>
      </main>
      <div>Footer</div>
    </div>
  );
};

export default PageStructure;
