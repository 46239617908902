import { EventType, InteractionType } from "@azure/msal-browser";
import { AuthenticatedTemplate, MsalAuthenticationTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { LinearProgress } from "@material-ui/core";
import React, { PropsWithChildren, Suspense, useEffect } from "react";
import { AuthenticatedApiWrapper } from "../../AuthenticatedApiWrapper";
import AuthError from "../../components/AuthError";
import AuthLoading from "../../components/AuthLoading";
import Login from "../../components/Login";
import { forgotPasswordRequest, loginRequest } from "../../utils/config";

interface Props {}

const LandingPage = (props: PropsWithChildren<Props>) => {
  const { instance } = useMsal();

  useEffect(() => {
    const callbackId = instance.addEventCallback(async (message: any) => {
      if (message.interactionType === InteractionType.Redirect) {
        if (message.eventType === EventType.LOGIN_FAILURE) {
          if (message.error.errorMessage) {
            if (message.error.errorMessage.indexOf("AADB2C90118") > -1) {
              await instance.loginRedirect(forgotPasswordRequest);
            }
          }
        } else if (message.eventType === EventType.LOGIN_SUCCESS) {
          const tfp = message?.payload.account.idTokenClaims.tfp;
          if (tfp === process.env.REACT_APP_AUTH_PROFILE_PASSWORD) {
            await instance.logoutRedirect();
          }
        } else if (message.eventType === EventType.LOGOUT_SUCCESS) {
          const tfp = message?.payload.account.idTokenClaims.tfp;
          if (tfp !== process.env.REACT_APP_AUTH_PROFILE) {
            await instance.loginRedirect();
          } else if (message.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
            // COULD USE THIS POTENTIALLY IF THEY GET AN ERROR WHILE GETTING A TOKEN
            // instance.logoutRedirect();
            console.log("ACQUIRE_TOKEN_FAILURE");
          } else {
            console.log("A REGULAR LOG OUT OCCURRED");
          }
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  return (
    <Suspense fallback={<LinearProgress />}>
      <AuthenticatedTemplate>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={loginRequest}
          errorComponent={AuthError}
          loadingComponent={AuthLoading}
        >
          <AuthenticatedApiWrapper>{props.children}</AuthenticatedApiWrapper>
        </MsalAuthenticationTemplate>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </Suspense>
  );
};

export default LandingPage;
