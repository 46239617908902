import InputBase from "@material-ui/core/InputBase";
import LinearProgress from "@material-ui/core/LinearProgress";
import Search from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useRef } from "react";
import useFarm from "../../hooks/useFarm";
import useFarms from "../../api/useFarms";
import useLanguage from "../../hooks/useLanguage";
import useToast from "../../hooks/useToast";
import { IFarm } from "../../types/farm";
import useStyles from "./styles";

export default function CountrySelect() {
  const classes = useStyles();
  const [farm, setFarm] = useFarm();
  const { t } = useLanguage();
  const toast = useToast();
  const seachBoxRef = useRef<HTMLInputElement | null>(null);
  const { QFarms } = useFarms();

  useEffect(() => {
    QFarms.error && toast.error(t("Can't retrieve farms"));
  }, [QFarms.error, t, toast]);

  return QFarms.isLoading || QFarms.isFetching ? (
    <div style={{ width: 250, marginRight: 16 }}>
      <LinearProgress />
    </div>
  ) : !QFarms.data || QFarms.data?.length <= 1 || !farm ? null : (
    <div style={{ width: 250, marginRight: 16 }}>
      <Autocomplete
        ref={seachBoxRef}
        fullWidth
        value={farm}
        onChange={(event: any, newValue: IFarm) => {
          setFarm(newValue);
        }}
        filterOptions={(options, state) => {
          const _options = options.filter(
            (o) =>
              o.farmName.toLowerCase().includes(state.inputValue.toLowerCase()) ||
              o.farmId?.toLowerCase().includes(state.inputValue.toLowerCase())
          );
          return _options;
        }}
        getOptionSelected={(option, value) => {
          return option.farmId === value.farmId;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={QFarms.data}
        getOptionLabel={(option) => option.farmName}
        autoHighlight
        blurOnSelect
        disableClearable
        renderInput={(params) => (
          <InputBase
            {...params.InputProps}
            {...params.inputProps}
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            className={classes.inputBase}
            startAdornment={<Search color="inherit" />}
          />
        )}
      />
    </div>
  );
}
