import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

interface Props {
  color?: string;
  opacity?: number;
}

const Home = ({ color = "#000", opacity = 0.7 }: Props) => {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1008.7 903">
        <polyline
          fill="none"
          stroke={color}
          strokeWidth="80px"
          opacity={opacity}
          strokeLinecap="round"
          strokeLinejoin="round"
          points="104.3,499 104.3,862 345.2,862 346.2,500.4 662.2,500.4 662.2,862 904.3,862 904.3,501.4 "
        />
        <polyline
          fill="none"
          stroke={color}
          strokeWidth="80px"
          opacity={opacity}
          strokeLinecap="round"
          strokeLinejoin="round"
          points="41.4,399.8 504.4,43.7 967.3,399.8 "
        />
      </svg>
    </SvgIcon>
  );
};

export default Home;
