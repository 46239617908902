import { useQuery } from "react-query";
import useAuth from "hooks/useAuth";
import useAxios from "hooks/useAxios";
import { IFarm } from "types/farm";
import { useEffect } from "react";
import useToast from "hooks/useToast";
import useLanguage from "hooks/useLanguage";

function useFarms() {
  const { axios, cancelToken } = useAxios();
  const { user } = useAuth();
  const toast = useToast();
  const { t } = useLanguage();

  const QFarms = useQuery<IFarm[], Error>(
    ["farms", user.email],
    async () =>
      await axios.get(`farms`, {
        cancelToken,
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 60 * 6,
      staleTime: 1000 * 60 * 60 * 6,
      enabled: !!user.email,
    }
  );
  useEffect(() => {
    QFarms.error && QFarms.error.message !== "Changed Page" && toast.error(t("Can't find farms"));
  }, [QFarms.error, t, toast]);

  return { QFarms };
}

export default useFarms;
