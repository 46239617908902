import React from "react";
import useAuth from "../../hooks/useAuth";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ExitToApp from "@material-ui/icons/ExitToApp";

interface IProps {
  menuExpanded?: boolean;
}

const User = ({ menuExpanded }: IProps) => {
  const { user, logout } = useAuth();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        paddingTop: 16,
        paddingBottom: 16,
      }}
    >
      {menuExpanded ? (
        <>
          <div>
            <AccountCircle fontSize="large" />
          </div>
          <Typography variant="h6">{user.name}</Typography>
          <Typography variant="caption">{user.email}</Typography>
          <div style={{ marginTop: 16 }}>
            <Button onClick={logout} variant="outlined" color="primary" size="small" endIcon={<ExitToApp />}>
              Logout
            </Button>
          </div>
        </>
      ) : (
        <>
          <div>
            <Tooltip title={`${user.name} ${user.email}`} placement="right">
              <AccountCircle fontSize="large" />
            </Tooltip>
          </div>
          <div style={{ marginTop: 16 }}>
            <IconButton onClick={logout} aria-label="Logout" color="primary">
              <ExitToApp />
            </IconButton>
          </div>
        </>
      )}
    </div>
  );
};

export default User;
