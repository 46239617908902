import { useTranslation } from "react-i18next";
// import { Locale } from "date-fns";
// import * as Locales from "date-fns/locale";

import { da, de, enGB, enUS, nl, fi, cs, ru, sk, fr } from "date-fns/locale";
const Locales = { da, de, enGB, enUS, nl, fi, cs, ru, sk, fr };

const useLanguage = (ns: string[] | string = []) => {
  const translations = useTranslation(ns);

  // This is to specifically name exceptions to the i18n library language codes and the browsers country codes being different.
  // i18n libraries use ISO codes and the browser uses NATO codes
  // E.G. Denmark in i18n is DK but the browser Danish is DA
  const countryCode = () => {
    switch (translations.i18n.language) {
      case "dk":
        return "da";
      default:
        return translations.i18n.language;
    }
  };
  const code = countryCode();
  const n = (num: number) => new Intl.NumberFormat(code, { maximumFractionDigits: num }).format;
  const lang = code.replace(/-/gi, "");
  // @ts-ignore
  const locale = Locales[lang] || Locales["enGB"];

  return {
    ...translations,
    // t: (_t: string) => translations.t(_t as unknown as TemplateStringsArray | TemplateStringsArray[]),
    d: new Intl.DateTimeFormat(code).format,
    code,
    n0: n(0),
    n1: n(1),
    n2: n(2),
    n3: n(3),
    weightMultiplier: code === "en-US" ? 2000 : 1000,
    locale,
  };
};

export default useLanguage;
