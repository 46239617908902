import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import React from "react";
import Logo from "../Components/Logo";
import { motion } from "framer-motion";
import useStyles from "./styles";
interface Props {
  full?: boolean;
}
const SuspenseFallback = ({ full = false }: Props) => {
  const classes = useStyles();
  return !full ? (
    <Backdrop open={true}>
      <CircularProgress />
    </Backdrop>
  ) : (
    <div className={classes.rootLowRes}>
      <div className={classes.root}>
        <motion.div animate={{ y: 200, opacity: 1 }} initial={{ y: 300, opacity: 0 }} transition={{ delay: 0 }}>
          <Box bgcolor="#fffc" display="flex" justifyContent="center">
            <Box p={2} width={400} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
              <Logo />

              <Box p={2} width="100%">
                <LinearProgress color="primary" />
              </Box>
            </Box>
          </Box>
        </motion.div>
      </div>
    </div>
  );
};

export default SuspenseFallback;
