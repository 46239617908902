import React from "react";
import logoCowConnect from "../../images/cowconnect-logo.png";
import logoCowConnectReverse from "../../images/cowconnect-logo-reverse.png";
import Box from "@material-ui/core/Box";

interface Props {
  reverse?: boolean;
  height?: number;
}

const Logo = ({ reverse = false, height = 100 }: Props) => {
  return (
    <Box m={1} display="flex" justifyContent="center">
      <img src={reverse ? logoCowConnectReverse : logoCowConnect} height={height} alt="Cow Connect" />
    </Box>
  );
};

export default Logo;
