import { useEffect } from "react";
import { useQuery } from "react-query";
import useAxios from "hooks/useAxios";
import { EModules, IModule } from "types/FarmModules";
import useFarm from "hooks/useFarm";
import useLanguage from "hooks/useLanguage";
import useToast from "hooks/useToast";

export default function useFarmModules() {
  const { axios, cancelToken } = useAxios();
  const [farm] = useFarm();
  const { t } = useLanguage();
  const toast = useToast();
  const QModules = useQuery<IModule[], Error>(
    ["farmmodules", farm.farmId],
    async () =>
      await axios.get(`farms/modules/${farm?.farmId}`, {
        cancelToken,
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 30,
      staleTime: 1000 * 60 * 30,
      enabled: !!farm?.farmId,
    }
  );

  useEffect(() => {
    QModules.error && QModules.error.message !== "Changed Page" && toast.error(t("Can't find any modules"));
  }, [QModules.error, t, toast]);

  const isModuleActive = (module: EModules) =>
    !!QModules.data?.find((_module) => _module.moduleId.toLowerCase() === module.toLowerCase());

  return {
    isModuleActive,
    QModules,
  };
}
