import { useMsal } from "@azure/msal-react";
import React from "react";
import LoginPrompt from "../../../Screens/LoginPrompt";

interface Props {}

const Login = (props: Props) => {
  const { instance } = useMsal();

  const login = async () => {
    await instance.handleRedirectPromise();
    const accounts = instance.getAllAccounts();
    if (accounts.length === 0) {
      console.log("NO ONE IS SIGNED IN");
      instance.loginRedirect();
    } else {
      console.log("SOME ONE IS ALREADY SIGNED IN");
    }
  };

  return <LoginPrompt login={login} />;
};

export default Login;
