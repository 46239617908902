import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";

const useAuth = () => {
  const { accounts, instance } = useMsal();
  const account = useAccount(accounts[0] || {});
  const isAuthenticated = useIsAuthenticated();

  const logout = async () => {
    if (account?.homeAccountId) {
      const currentAccount = instance.getAccountByHomeId(account?.homeAccountId);
      await instance.logoutRedirect({
        account: currentAccount,
        postLogoutRedirectUri: "/",
      });
    }
  };

  const login = async () => {
    await instance.handleRedirectPromise();
    const accounts = instance.getAllAccounts();
    if (accounts.length === 0) {
      instance.loginRedirect();
    } else {
      console.log("SOME ONE IS ALREADY SIGNED IN");
    }
  };

  return isAuthenticated
    ? ({
        user: {
          name: account?.name,
          email: account?.username,
        },
        logout,
        login,
      } as any)
    : null;
};

export default useAuth;
