import React, { PropsWithChildren, ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

interface Props {
  children: ReactNode;
}
const queryClient = new QueryClient();
const ReactQuery = (props: PropsWithChildren<Props>) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      {props.children}
    </QueryClientProvider>
  );
};

export default ReactQuery;
