export interface IRole {
  roleId: ERole;
  roleName: string;
}

export interface IUserRole {
  email: string;
  role: IRole;
}

export enum ERole {
  none,
  admin,
  advisor,
}
