import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    backgroundColor: "#777",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  centeredToolbar: {
    justifyContent: "space-between",
  },
  logo: {
    margin: 8,
    color: theme.palette.primary.main,
  },
}));
