import { Box, LinearProgress } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import { Apps, Dashboard, Devices, DynamicFeed, Money, People, PeopleAlt } from "@material-ui/icons";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import SettingsEthernet from "@material-ui/icons/SettingsEthernet";
import { CSSProperties } from "@material-ui/styles";
import clsx from "clsx";
import React, { Fragment, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import useRole from "../../api/useRole";
import { Home } from "../../Components/Icons";
import User from "../../Components/User";
import useFarm from "../../hooks/useFarm";
import useLanguage from "../../hooks/useLanguage";
import useStyles from "./style";

type SubMenuProps = {
  children: any;
  text: string;
  icon: any;
  menuExpanded: boolean;
};

type NavigationLinkProps = {
  icon: any;
  text: string;
  link: string;
};

const activeStyle: CSSProperties = {
  fontWeight: "bold",
  backgroundColor: "#ddd",
};

export default function SideMenu() {
  const [menuExpanded, setMenuExpanded] = useState(true);
  const { QRole, isAdmin } = useRole();

  const toggleMenuExpanded = () => {
    setMenuExpanded((prevState) => !prevState);
  };

  const classes = useStyles();
  const { t } = useLanguage();
  const [farm] = useFarm();

  const farmName = farm.farmName.length > 18 ? `${farm.farmName.substr(0, 15)}...` : farm.farmName;
  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: menuExpanded,
        [classes.drawerClose]: !menuExpanded,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: menuExpanded,
          [classes.drawerClose]: !menuExpanded,
        }),
      }}
    >
      <Box>
        <div className={classes.toolbar} />
        {QRole.isLoading ? (
          <LinearProgress />
        ) : (
          <>
            <List>
              <Box justifyContent={menuExpanded ? "flex-end" : "center"}>
                <IconButton color="inherit" aria-label="open drawer" onClick={toggleMenuExpanded}>
                  {menuExpanded ? <ChevronLeft /> : <ChevronRight />}
                </IconButton>
              </Box>
              <NavigationLink icon={<Home />} text={t("Home")} link="/" />

              {isAdmin ? (
                <SubMenu menuExpanded={menuExpanded} text={farmName} icon={<AccountCircle />}>
                  <NavigationLink icon={<AccountCircle />} text={t("Farm Details")} link="/farm/details" />
                  <NavigationLink icon={<SettingsEthernet />} text={t("Connections")} link="/farm/connections" />
                  <NavigationLink icon={<People />} text={t("Farm Access")} link="/farm/access" />
                  <NavigationLink icon={<Devices />} text={t("Device Dashboard")} link="/farm/devices" />
                  <NavigationLink icon={<DynamicFeed />} text={t("Farm Management")} link="/farm/management" />
                  <NavigationLink icon={<Money />} text={t("Financial")} link="/farm/financial" />
                </SubMenu>
              ) : null}
            </List>
            <Divider />
            {isAdmin ? (
              <List component="nav" subheader={<ListSubheader component="div">{t("Admin Functions")}</ListSubheader>}>
                <NavigationLink icon={<Apps />} text={t("Modules")} link="/modules" />
                <NavigationLink icon={<PeopleAlt />} text={t("Roles")} link="/roles" />
                <NavigationLink icon={<SettingsEthernet />} text={t("Companies")} link="/companies" />
                <NavigationLink icon={<Devices />} text={t("Unused Devices")} link="/unuseddevices" />
              </List>
            ) : null}
          </>
        )}
      </Box>
      <Box>
        <Divider />
        <User menuExpanded={menuExpanded} />
      </Box>
    </Drawer>
  );
}

const NavigationLink = ({ icon, link, text }: NavigationLinkProps) => {
  const classes = useStyles();
  const [isActive, setIsActive] = useState(false);
  const loc = useLocation();

  useEffect(() => {
    setIsActive(loc && loc.pathname && link !== "/" ? loc.pathname.startsWith(link) : false);
  }, [loc, link]);

  return (
    <NavLink to={link} className={classes.navLink}>
      <ListItem button style={isActive ? activeStyle : {}}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText style={{ whiteSpace: "nowrap" }} primary={text} />
      </ListItem>
    </NavLink>
  );
};

const SubMenu = ({ children, text, icon, menuExpanded }: SubMenuProps) => {
  const [open, setOpen] = useState(false);
  const loc = useLocation();
  const classes = useStyles();

  const handleClick = () => setOpen((state) => !state);

  useEffect(() => {
    (children.length ? children : [children]).forEach((c: any) => {
      if (loc.pathname.startsWith(c?.props?.link)) setOpen(true);
    });
  }, [children, loc]);

  return (
    <Fragment>
      <ListItem button onClick={handleClick} className={clsx({ [classes.submenuLinkAnchor]: !menuExpanded && open })}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText style={{ whiteSpace: "nowrap", textOverflow: "ellipsis" }} primary={text} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          className={clsx(classes.nested, {
            [classes.nestedClosed]: !menuExpanded,
            [classes.submenuLinks]: !menuExpanded,
          })}
        >
          {children}
        </List>
      </Collapse>
    </Fragment>
  );
};
