export default (() => {
  // Opera 8.0+
  var isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0;

  // Firefox 1.0+
  var isFirefox = typeof InstallTrigger !== "undefined";

  // Safari 3.0+ "[object HTMLElementConstructor]"
  var isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(!window["safari"] || (typeof window.safari !== "undefined" && window.safari.pushNotification));

  // Internet Explorer 6-11
  var isIE = /*@cc_on!@*/ false || !!document.documentMode;

  // Edge 20+
  var isEdge = !isIE && !!window.StyleMedia;

  // Chrome 1 - 79
  var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

  // Edge (based on chromium) detection
  var isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") !== -1;

  // Blink engine detection
  var isBlink = (isChrome || isOpera) && !!window.CSS;

  const ua = navigator.userAgent;
  var isApple = ua.includes("Macintosh") || ua.includes("iPhone") || ua.includes("iPad");
  return {
    isApple,
    isFirefox,
    isChrome,
    isSafari,
    isOpera,
    isIE,
    isEdge,
    isEdgeChromium,
    isBlink,
    hasString: (str) => navigator.userAgent.includes(str),
    which: () => {
      return isFirefox
        ? "Mozilla Firefox"
        : isChrome
        ? "Google Chrome"
        : isSafari
        ? "Apple Safari"
        : isOpera
        ? "Opera"
        : isIE
        ? "Microsoft Internet Explorer"
        : isEdge
        ? "Microsoft Edge"
        : isEdgeChromium
        ? "Microsoft Edge"
        : isBlink
        ? "Blink"
        : "an unknown browser";
    },
    isSupported: () => !isIE && !isOpera, //&& !isEdge // isChrome || isFirefox || isSafari || isEdgeChromium || isBlink
  };
})();
