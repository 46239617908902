import { useEffect, useRef, useState } from "react";
import Axios from "axios";
import React from "react";

const useVersionCheck = () => {
  const [init, setInit] = useState<boolean>(false);
  const version = useRef<number>();

  const fetchVersion = React.useCallback(() => {
    Axios.get("/version.json", {
      headers: {
        Accept: "application/json",
        "Cache-Control": "max-age=0 no-cache no-store must-revalidate max-stale=0 post-check=0 pre-check=0",
        Pragma: "no-cache",
        Vary: "*",
        Expires: "Mon, 26 Jul 1997 05:00:00 GMT",
      },
    })
      .then((res) => res.data)
      .then((res) => {
        version.current = Number(res.v);
        if (!init) setInit(true);
      });
  }, [init]);

  useEffect(() => {
    fetchVersion();
  }, [fetchVersion]);

  const doReload = () => window.location.reload();

  return {
    initDone: init,
    correct: version.current && version.current === Number(process.env.REACT_APP_VERSION),
    version: version.current,
    fetchVersion,
    doReload,
  };
};

export default useVersionCheck;
