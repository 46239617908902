import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import XHR from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import backendOptions from "./backendOptions";

i18n
  .use(XHR)
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    saveMissing: false,
    debug: false,
    backend: backendOptions,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
