import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

interface Props {
  color?: string;
  opacity?: number;
}

const BulkTank = ({ color = "#000", opacity = 0.7 }: Props) => {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
          fill={color}
          opacity={opacity}
          d="M314.9,89.2V67H196.1v22.2c-106.5,18.6-185.4,86-185.4,166.4c0,58.6,41.9,110.3,105.9,141.2l4,59.1H133h0.9h9.1v-48
	c33.7,12.2,71.9,19.2,112.5,19.2s78.8-6.9,112.5-19.2v48h8h2h11.3l4-58.6c64.6-30.9,107-82.9,107-141.8
	C500.3,175.2,421.4,107.8,314.9,89.2z M255.5,399.7c-12.1,0-21.8-9.8-21.8-21.8s9.8-21.8,21.8-21.8c12.1,0,21.8,9.8,21.8,21.8
	S267.6,399.7,255.5,399.7z"
        />
      </svg>
    </SvgIcon>
  );
};

export default BulkTank;
