import { HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import React from "react";

interface Props {}
interface IWebSocket {
  connection: HubConnection | null;
  join: (name: string) => Promise<void>;
  devices: IDeviceWeightState[];
}

const WebSocketsCtx = React.createContext<IWebSocket | null>(null);

enum EDeviceWeightActionType {
  SET_WEIGHT = "SET_WEIGHT",
}

export interface IDeviceWeightState {
  value: number | string;
  DeviceId: string;
}

export interface IDeviceWeightAction {
  type: EDeviceWeightActionType;
  payload: IDeviceWeightState;
}

const reducer = (state: IDeviceWeightState[], action: IDeviceWeightAction) => {
  const { type, payload } = action;
  switch (type) {
    case EDeviceWeightActionType.SET_WEIGHT:
      return [...state.filter((s) => s.DeviceId !== payload.DeviceId), payload];
    default:
      return state;
  }
};

const WebSockets = (props: React.PropsWithChildren<Props>) => {
  const [connection, setConnection] = React.useState<HubConnection | null>(null);
  const [devices, dispatch] = React.useReducer(reducer, []);

  React.useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_SCALE_GATEWAY as string)
      .withAutomaticReconnect()
      .build();

    setConnection(newConnection);
    return () => {
      newConnection.stop();
    };
  }, []);

  React.useEffect(() => {
    connection?.onreconnected(() => {
      console.log("Connection Reconnected");
    });

    connection?.onreconnecting(() => {
      console.log("Connection Reconnecting");
    });

    connection?.onclose(() => {
      console.log("Connection Closing");
    });

    connection?.on(ESubscriptions.ReceiveUpdate, (_res: string) => {
      const res: IDeviceWeightState = JSON.parse(_res);
      dispatch({ type: EDeviceWeightActionType.SET_WEIGHT, payload: res });
    });

    return () => {
      connection?.stop();
    };
  }, [connection]);

  async function join(groupName: string): Promise<void> {
    if (!connection) throw "No Connection";
    if (!HubConnectionState.Connected) throw "Not Connected";
    connection
      .invoke("JoinGroup", groupName)
      .then((g) => {
        console.log("Joined Group", groupName);
      })
      .catch((err) => {
        console.log("Error joining group", err);
      });
  }

  return <WebSocketsCtx.Provider value={{ connection, join, devices }}>{props.children}</WebSocketsCtx.Provider>;
};

export default WebSockets;

export const useWebSocketConnection = () => {
  const webSocketConnection = React.useContext(WebSocketsCtx);
  if (!webSocketConnection) throw new Error("ERROR");
  return webSocketConnection;
};
export enum ESubscriptions {
  "ReceiveUpdate" = "ReceiveUpdate",
}
