import React from "react";
import { IInvoice } from "../../types/finance";
import format from "date-fns/format"
import useLanguage from "../../hooks/useLanguage";
import { DownloadButton } from "./Finance";

interface Props {
  invoice?: IInvoice;
}

const Invoice = (props: Props) => {
  const { t, d } = useLanguage();
  return !props.invoice ? null : <div>
    <header style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ flexBasis: 100 }}></div>
      <h1 style={{ fontSize: 26, textAlign: "center", flexGrow: 1 }}>Cowconnect ApS</h1>
      <div style={{ flexBasis: 100, display: "flex", justifyContent: "flex-end" }}>
        <DownloadButton disabled={!props.invoice} invoice={props.invoice.bookedInvoiceNumber} />
      </div>
    </header>

    <div style={{ paddingTop: 40, paddingRight: 60, paddingLeft: 60 }}>

      <section style={{ display: "flex", marginBottom: 30 }}>
        <div style={{ flex: 2 }}>
          <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>{t("Customer")}</p>
          <hr />
          <div>
            <p>
              <div style={{ fontWeight: "bold" }}>{props.invoice.recipient.name}</div>
              <div>{props.invoice.recipient.address}</div>
              <div>{props.invoice.recipient.zip}</div>
              <div>{props.invoice.recipient.city}</div>
              <div>{props.invoice.recipient.country}</div>
            </p>
          </div>
        </div>
        <div style={{ flex: 1, marginLeft: 16 }}>
          <p style={{ fontWeight: "bold", textTransform: "uppercase" }}>{t("Invoice")}</p>
          <hr />
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <div style={{ width: "50%" }}>
              {t("Invoice number")}:
            </div>
            <div style={{ width: "50%" }}>
              {props.invoice.bookedInvoiceNumber}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <div style={{ width: "50%" }}>
              {t("Date of invoice")}:
            </div>
            <div style={{ width: "50%" }}>
              {props.invoice.date ? d(new Date(props.invoice.date)) : ""}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <div style={{ width: "50%" }}>
              {t("Client number")}:
            </div>
            <div style={{ width: "50%" }}>
              {props.invoice.customer.customerNumber}
            </div>
          </div>
        </div>
      </section>

      <section style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 30 }}>
        {t("For a breakdown of charges, download PDF.")}
        <DownloadButton disabled={!props.invoice} invoice={props.invoice.bookedInvoiceNumber} />
      </section>

      <section style={{ display: "flex" }}>
        <div style={{ flex: 2 }}>
          <p style={{ fontSize: 10 }}>
            ({t("Amount")}, {t("VAT exempt")}: <span style={{ fontWeight: "bold" }}>{props.invoice.grossAmount ? new Intl.NumberFormat().format(props.invoice.grossAmount) : "0,00"}
            </span> - {t("Amount")} , {t("subject to VAT")}: <span style={{ fontWeight: "bold" }}>{props.invoice.vatAmount ? new Intl.NumberFormat().format(props.invoice.vatAmount) : "0,00"}</span>)
          </p>
        </div>
        <div style={{ flex: 1, textAlign: "right" }}>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1, textAlign: "right" }}>
              <p>{t("Subject total")} : </p>
              <p>{props.invoice.vatAmount ? new Intl.NumberFormat().format(props.invoice.vatAmount) : "0,00"}% {t("VAT")} : </p>
              <p style={{ fontWeight: "bold" }}>{t("Total")} {props.invoice.currency} : </p>
            </div>
            <div style={{ flex: 1, textAlign: "right" }}>
              <p>{props.invoice.netAmount ? new Intl.NumberFormat().format(props.invoice.netAmount) : "0,00"}</p>
              <p>{props.invoice.vatAmount ? new Intl.NumberFormat().format(props.invoice.vatAmount) : "0,00"}</p>
              <p style={{ fontWeight: "bold" }}>{props.invoice.grossAmount ? new Intl.NumberFormat().format(props.invoice.netAmount) : "0,00"}</p>
            </div>
          </div>
          <hr />
        </div>
      </section>

      <section style={{ paddingTop: 30 }}>
        <div>
          <p>
            <div>{t("Terms of payment")}: {t("Netto 14 dage")} - {t("Final payment date")} <span style={{ fontWeight: "bold" }}>{props.invoice.dueDate ? d(new Date(props.invoice.dueDate)) : ""}</span></div>
            <div>{t("When paying by bank transfer")}, {t("please state invoice number")} <span style={{ fontWeight: "bold" }}>{props.invoice.bookedInvoiceNumber} </span> </div>
          </p>
        </div>
        <div>
          <p style={{ fontStyle: "italic" }}>{t("Interest will be charged on overdue payment pursuant to applicable law")}.</p>
        </div>
      </section>
      <hr />
    </div>

    <footer>
      <div style={{ fontSize: 10, textAlign: "center" }}>
        <p>
          <div><span style={{ fontWeight: "bold" }}>Cowconnect ApS</span> - Middelfartvej 77 - 5466 Asperup - Danmark - Corp.reg.no.: 38546635</div>
          <div>{t("Tel")}: 29257819 - {t("Mail")}: jesper@cowconnect.dk -</div>
          <div>{t("Bank")}: Fynske Bank - {t("Reg")}. {t("number")}:6858 / {t("Account")} {t("number")}:1057400 - IBAN {t("number")}: DK8668580001057400 - {t("SWIFT code")}: VESBDK22</div>
        </p>
      </div>
    </footer>
  </div>;
};

export default Invoice;
