import makeStyles from "@material-ui/core/styles/makeStyles";

export default makeStyles((theme) => ({
  inputBase: {
    width: "100%",
    color: theme.palette.common.white,
    "& input": {
      padding: 10,
      marginLeft: 5,
      borderRadius: 4,
      fontSize: 14,
      backgroundColor: "rgba(255,255,255,0.2)",
      color: "rgba(255,255,255,0.8)",
      transition: theme.transitions.create(["background-color", "color"]),
      "&:focus": {
        backgroundColor: "rgba(255,255,255,0.8)",
        color: theme.palette.common.black,
      },
    },
  },
}));
