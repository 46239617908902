import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import RefreshIcon from "@material-ui/icons/Refresh";
import { motion } from "framer-motion";
import React, { ReactNode, useEffect, useRef } from "react";
import Logo from "../Components/Logo";
import useVersionCheck from "../hooks/useVersionCheck";
import useStyles from "../Screens/styles";

interface Props {
  children: ReactNode;
}

const Refresh = ({ children }: Props) => {
  const version = useVersionCheck();
  const timer = useRef<any>();
  const classes = useStyles();

  useEffect(() => {
    version.fetchVersion();
    timer.current = setInterval(version.fetchVersion, 1000 * 60 * 5);
    return () => {
      clearInterval(timer.current);
    };
  }, [version]);

  const doReload = () => window.location.reload();

  return version.initDone && version.correct ? (
    <>{children}</>
  ) : (
    <div className={classes.root}>
      <motion.div animate={{ y: 200, opacity: 1 }} initial={{ y: 300, opacity: 0 }} transition={{ delay: 1 }}>
        <Box bgcolor="#fffc" display="flex" justifyContent="center">
          <Box p={2} width={400} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
            <Logo />
            {!version.initDone ? <h1>Checking for updates...</h1> : <h1>New version available...</h1>}
            {!version.initDone && (
              <Typography variant="subtitle1" component="div" style={{ textAlign: "center" }}>
                We're trying to determine if you have the latest version of the application.
              </Typography>
            )}
            <Typography variant="body1" component="div" style={{ marginTop: 8, marginBottom: 32 }}>
              {!version.initDone && "It seem's to be taking a long time, try reloading the page"}
              {version.initDone && !version.correct && "There has been an update, please reload the page"}
            </Typography>
            <Button variant="contained" color="primary" size="large" onClick={doReload} startIcon={<RefreshIcon />}>
              RELOAD
            </Button>
          </Box>
        </Box>
      </motion.div>
    </div>
  );
};

export default Refresh;
