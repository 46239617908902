export function isError(error: unknown): error is Error {
  return error instanceof Error;
}

export function saveInLocal(key: string, value: any, type?: "string" | "number" | "json" | "boolean") {
  var oldValue = getFromLocal(key);
  if (!type || type === "string") localStorage.setItem(key, value);
  if (type?.toLowerCase() === "json") localStorage.setItem(key, JSON.stringify(value));
  if (type?.toLowerCase() === "number") localStorage.setItem(key, value.toString());
  if (type?.toLowerCase() === "boolean") localStorage.setItem(key, value.toString());
  return { oldValue, newValue: value };
}

export function removeFromLocal(key: string) {
  var oldValue = getFromLocal(key);
  localStorage.removeItem(key);
  return { oldValue };
}

export function getFromLocal(key: string, type?: "string" | "number" | "json" | "boolean") {
  var data = localStorage.getItem(key);
  if (data && type?.toLowerCase() === "number") return Number(data);
  else if (data && type?.toLowerCase() === "json") return JSON.parse(data);
  else if (data && type?.toLowerCase() === "boolean") return Boolean(data);
  return data || null;
}

export const round = (decimalPlaces: number) => (num: number) =>
  Math.round(num * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);

export const isGUID = (str: string) => {
  if (str.length !== 36) return false;
  const _arr = str.split("-");
  if (_arr.length !== 5) return false;
  if (_arr[0].length !== 8) return false;
  if (_arr[1].length !== 4) return false;
  if (_arr[2].length !== 4) return false;
  if (_arr[3].length !== 4) return false;
  if (_arr[4].length !== 12) return false;
  return true;
};

export const mergeObjectKeys = (obj: any) => Object.keys(obj).reduce((t, k) => ({ ...t, ...obj[k] }), {});

export const createBase64 = (file: File | undefined, imageWidth = 200, cb: (base64: string) => void) => {
  const reader = new FileReader();
  if (reader && file) {
    const img = new Image();
    reader.onloadend = () => {
      img.src = reader.result as string;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        if (img.width > imageWidth) {
          const aspectRatio = imageWidth / img.width;
          img.width = imageWidth;
          img.height *= aspectRatio;
        }
        canvas.height = img.height;
        canvas.width = img.width;
        ctx?.drawImage(img, 0, 0, img.width, img.height);
        cb(canvas.toDataURL(file.type, 0.9));
      };
    };
    reader.readAsDataURL(file);
  }
};

export function validateEmail(_email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(_email).toLowerCase());
}

export const isVersionOrBetter = (version: string | undefined, versionToCheck: string | undefined): boolean => {
  if (!version || !versionToCheck || !version.includes(".") || !versionToCheck.includes(".")) return false;
  const _version = version.split(".").map(Number);
  const _versionToCheck = versionToCheck.split(".").map(Number);
  if (!_versionToCheck || !_version) return false;
  return _version[0] > _versionToCheck[0]
    ? true
    : _version[0] === _versionToCheck[0] && _version[1] > _versionToCheck[1]
    ? true
    : _version[0] === _versionToCheck[0] && _version[1] === _versionToCheck[1] && _version[2] >= _versionToCheck[2]
    ? true
    : false;
};
