import React from "react";
import { useQuery } from "react-query";
import { IInvoice } from "../types/finance";
import useFarm from "../hooks/useFarm";
import useLanguage from "../hooks/useLanguage";
import useRole from "./useRole";
import useToast from "../hooks/useToast";
import useAxios from "hooks/useAxios";

function useInvoices() {
  const { axios, cancelToken } = useAxios();
  const { t } = useLanguage();
  const { isAdmin } = useRole();
  const [farm] = useFarm();
  const toast = useToast();

  const QInvoices = useQuery<IInvoice[], Error>(
    [`invoices`, farm.farmId],
    async () => {
      return await axios.get(`invoices/${farm.farmId}`, {
        cancelToken,
      });
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 60,
      staleTime: 1000 * 60 * 60,
      enabled: !!farm?.farmId && isAdmin,
      retry: 0,
    }
  );

  React.useEffect(() => {
    QInvoices.error && QInvoices.error.message !== "Changed Page" && toast.error(t("Can't find any data"));
  }, [QInvoices.error]);

  return {
    QInvoices,
  };
}

export default useInvoices;
