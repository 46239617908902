import React from "react";
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import ExitToAppOutlined from "@material-ui/icons/ExitToAppOutlined";
import Logo from "../Components/Logo";
import Box from "@material-ui/core/Box";
import { motion } from "framer-motion";

interface Props {
  text?: string;
}

const LoginInProgress = ({ text = "Authenticating" }: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <motion.div animate={{ y: 200, opacity: 1 }} initial={{ y: 300, opacity: 0 }} transition={{ delay: 0 }}>
        <Box bgcolor="#fffc" display="flex" justifyContent="center">
          <Box p={2} width={400} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
            <Logo />
            <Typography variant="h4" component="div" className={classes.prompt}>
              {text}...
            </Typography>
            <Box p={2} width="100%">
              <LinearProgress />
            </Box>
            <Button
              fullWidth
              disabled={true}
              size="large"
              variant="contained"
              color="primary"
              endIcon={<ExitToAppOutlined style={{ marginLeft: 32 }} />}
            >
              Login
            </Button>
          </Box>
        </Box>
      </motion.div>
    </div>
  );
};

export default LoginInProgress;
