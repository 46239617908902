import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

interface Props {
  color?: string;
  opacity?: number;
}

const CowConnect = ({ color = "#000", opacity = 0.7 }: Props) => {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 800">
        <path
          style={{ fill: color, opacity }}
          d="M590.8,78.8c-30.6,30.7-69.3,43.5-107.3,58c-29.3,11.2-61.7,24.2-64.4,59.2
	c-2.9,37.2,26.3,56.8,57.2,71.4c31.6,14.9,55.4,35.7,52,74.5c-3.8,42.5,17.8,71.6,47.4,98.4c45.7,41.3,90.4,83.9,135.1,126.3
	c13,12.3,26.7,24.1,21.7,46.2c-22.6,100.8-98.9,144.9-195.4,109.7c-40.7-14.9-80.4-17.2-122.8-10.4c-70.4,11.2-142.8,16.4-210.4-7.6
	c-66.9-23.7-116.2-4-162.4,40.9c-12.2,11.9-36.2,21.1-20.7,42.6c16.8,23.3,35.9,8.2,50.6-5.8c37.5-35.7,76.4-43.3,128.5-27.8
	c71.6,21.2,146.9,22.2,221.4,6.7c30.5-6.3,60.9-7,91.2,3.9c124.2,44.4,167.4,29.3,234.2-80.8c7.5-12.4,14.4-25.1,22.2-37.3
	c24-37.7,19.6-71.7-12.7-102.5c-47.2-45-93.6-91-142-134.8c-21.1-19.1-35.8-36.8-35.1-68.5c1.1-46.5-23.2-82.1-62.9-107.5
	c-14.5-9.3-41.7-10.6-39.7-31.6c2.2-23.5,30.6-20.5,47.8-28.3c57.8-25.9,120.5-44.3,155.5-103.4c9.9-16.8,22.6-36.1,8.9-55.4
	C675.4-3.9,653.4-0.9,633.5,2.7c-62.4,11.2-124.4,25.8-187.2,33.8c-76.3,9.7-147.1,25-181.7,110.1C227.8,91.8,190,48.5,134,25.7
	c-37.1-15.2-62.6-6-77.1,33.3C26,142.5,45.4,227.8,111.3,286.8c14.1,12.6,30.3,34.6,50.5,15.6c21.5-20.3-0.6-34.2-15.1-47.8
	c-39.3-36.7-60.7-80.2-53.3-135.5c6.9-52,17.9-57.7,59.5-27.6c49.6,35.9,74.1,87.9,91.3,144.2c5.4,17.7,10.4,39.1,34.6,31.7
	c24.3-7.4,17.2-29.3,10.9-45.9c-13.6-35.6-12.9-49.9,26.3-81.7C402.4,69.3,496.9,88.6,590.8,78.8z"
        />
      </svg>
    </SvgIcon>
  );
};

export default CowConnect;
