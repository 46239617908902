import ErrorBoundary from "./ErrorBoundary";
import Routes from "./Routes";
import Farm from "./Farm";
import Refresh from "./Refresh";
import React, { memo, Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import PageStructure from "../Layout/PageStructure";
import SuspenseFallback from "../Screens/SuspenseFallback";
import ErrorFallback from "../Screens/ErrorFallback";
import { SnackbarProvider } from "notistack";
import { ReactQueryDevtools } from "react-query/devtools";
import Auth from "./Auth";
// import AuthLegacy from "./AuthLegacy";
import Theme from "./Theme";
import ReactQuery from "./ReactQuery";
import WebSocketsProvider from "./WebSockets";

const Providers = () => {
  return (
    <Theme>
      {/* <AuthLegacy> */}
      <ReactQuery>
        <Refresh>
          <ReactQueryDevtools initialIsOpen={false} />
          <SnackbarProvider maxSnack={5}>
            <WebSocketsProvider>
              <Router>
                <Auth>
                  <Farm>
                    <ErrorBoundary fallback={<ErrorFallback />}>
                      <PageStructure>
                        <Suspense fallback={<SuspenseFallback />}>
                          <Routes />
                        </Suspense>
                      </PageStructure>
                    </ErrorBoundary>
                  </Farm>
                </Auth>
              </Router>
            </WebSocketsProvider>
          </SnackbarProvider>
        </Refresh>
      </ReactQuery>
      {/* </AuthLegacy> */}
    </Theme>
  );
};

export default memo(Providers);
