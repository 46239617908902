import { useEffect } from "react";
import { useQuery } from "react-query";
import { ERole, IRole } from "../Features/Roles/types";
import useAxios from "hooks/useAxios";
import useLanguage from "../hooks/useLanguage";
import useToast from "../hooks/useToast";

const useRole = () => {
  const { axios, cancelToken } = useAxios();
  const toast = useToast();
  const { t } = useLanguage();

  const QRole = useQuery<IRole, Error>(["role"], async () => await axios.get(`userrole`), {
    refetchOnWindowFocus: false,
    cacheTime: 1000 * 60 * 60,
    staleTime: 1000 * 60 * 60,
    retry: 1,
  });

  useEffect(() => {
    QRole.error && QRole.error.message !== "Changed Page" && toast.error(t("Can't find user role"));
  }, [QRole.error, t, toast]);

  return {
    QRole,
    isAdmin: QRole.isSuccess && QRole.data?.roleId === ERole.admin,
    isAdvisor: QRole.isSuccess && QRole.data?.roleId === ERole.advisor,
    isError: QRole.isError,
  };
};

export default useRole;
