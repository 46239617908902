import React from "react";
import LoginError from "./LoginError";
import useStyles from "./styles";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import ExitToAppOutlined from "@material-ui/icons/ExitToAppOutlined";
import browser from "../lib/browserDetection";
import useLanguage from "../hooks/useLanguage";
import Logo from "../Components/Logo";
import { motion } from "framer-motion";

interface Props {
  error?: any;
  login: any;
}

const LoginPrompt = ({ error, login }: Props) => {
  const { t } = useLanguage();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <motion.div animate={{ y: 200, opacity: 1 }} initial={{ y: 300, opacity: 0 }} transition={{ delay: 1 }}>
        <Box bgcolor="#fffc" display="flex" justifyContent="center">
          <Box p={2} width={400} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
            <Logo />
            {!!error && <LoginError error={error} />}
            <Box mt={2} width="100%">
              {browser.isSupported() ? (
                <Button
                  onClick={login}
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                  endIcon={<ExitToAppOutlined style={{ marginLeft: 32 }} />}
                  disabled={!browser.isSupported()}
                >
                  Login
                </Button>
              ) : (
                <Card style={{ margin: 32, padding: 16, maxWidth: 500 }}>
                  <p>
                    {t(
                      `You are using ${browser.which()}. Unfortunetly, we can not support this browser because we strive to provide the best experience possible and feel this browser hinders that.`
                    )}
                  </p>
                  <p>{t("We recommend you try using")}...</p>
                  <p style={{ textAlign: "center" }}>
                    <a href="https://www.google.com/chrome/" rel="noopener noreferrer" target="_blank">
                      Google Chrome
                    </a>
                    or
                    <a href="https://www.mozilla.org/en-US/firefox/new/" rel="noopener noreferrer" target="_blank">
                      Firefox
                    </a>
                  </p>
                </Card>
              )}
            </Box>
          </Box>
        </Box>
      </motion.div>
    </div>
  );
};

export default LoginPrompt;
