import React from "react";
import { useMutation } from "react-query";
import useFarm from "../hooks/useFarm";
import useLanguage from "../hooks/useLanguage";
import useToast from "../hooks/useToast";
import { saveAs } from "file-saver";
import useAxios from "hooks/useAxios";

function useInvoicePdf() {
  const { axios } = useAxios();
  const { t } = useLanguage();
  const [farm] = useFarm();
  const toast = useToast();

  const downloadPDF = useMutation(
    async ({ invoice, filename }: { invoice: string; filename: string }): Promise<BlobPart> => {
      return await axios.get(`invoices/pdf/${farm.farmId}?link=${invoice}`, {
        headers: {
          "Content-type": "application/pdf;charset=UTF-8",
        },
        responseType: "blob",
      });
    },
    {
      onSuccess: (res, vars) => {
        const blob = new Blob([res], { type: "application/pdf" });
        saveAs(blob, vars.filename);
      },
    }
  );

  React.useEffect(() => {
    downloadPDF.isError &&
      (downloadPDF.error as Error)?.message !== "Changed Page" &&
      toast.error(t("Error downloading file"));
  }, [downloadPDF.error]);

  return downloadPDF;
}

export default useInvoicePdf;
