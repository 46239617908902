import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import useLanguage from "../hooks/useLanguage";
import languages from "./languages";
import useStyles from "./styles";
import browser from "../lib/browserDetection";

interface Props {
  color?: boolean;
}
type Language = {
  code: string;
  title: string;
};

const LanguageSelect = ({ color = false }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>();
  const [language, setLanguage] = useState<Language | null>();
  const { t, i18n } = useLanguage();
  const classes = useStyles();

  useEffect(() => {
    const foundLanguage = languages.find((l) => l.code === i18n.language);
    foundLanguage ? setLanguage(foundLanguage) : setLanguage(languages.find((l) => l.code === "en-GB"));
  }, [i18n.language]);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget);

  const selectLanguage = (lng: string) => {
    setAnchorEl(null);
    lng && i18n.changeLanguage(lng, (x) => x && console.error(x));
  };

  const handleClose = (e: React.MouseEvent) => {
    setAnchorEl(null);
  };

  const flagFromCode = (c: string | undefined) => {
    if (!c) return;
    let code = String(c).toUpperCase();
    if (code === "CS") code = "CZ";
    if (code.includes("-")) code = code.slice(code.indexOf("-") + 1);
    return browser.isApple ? (
      <span className={classes.flag}>
        {code ? code.replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397)) : ""}
      </span>
    ) : (
      <img src={`/locales/${c}/flag.png`} className={classes.flagImage} alt={c} />
    );
  };

  return (
    <div style={{ width: 130 }}>
      <Button size="small" onClick={handleClick} style={{ color: color ? "black" : "white" }}>
        {flagFromCode(language?.code)}
        {language?.title || t("Change Language")}
      </Button>
      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {languages.map((l: any, index: number) => {
          return (
            <MenuItem key={index} onClick={(e) => selectLanguage(l.code)}>
              <span className={classes.flag}>{flagFromCode(l?.code)}</span>
              {t(l.title)}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default LanguageSelect;
