import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { motion } from "framer-motion";
import RefreshIcon from "@material-ui/icons/Refresh";
import useLanguage from "../hooks/useLanguage";
import useStyles from "./styles";
import Logo from "../Components/Logo";

const ErrorFallback = () => {
  const { t } = useLanguage();
  const classes = useStyles();

  const doReload = () => window.location.reload();

  return (
    <div className={classes.root}>
      <motion.div animate={{ y: 200, opacity: 1 }} initial={{ y: 300, opacity: 0 }} transition={{ delay: 1 }}>
        <Box bgcolor="#fffc" display="flex" justifyContent="center">
          <Box p={2} width={400} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
            <Logo />
            <Typography variant="h4" component="div" className={classes.prompt}>
              {t("There appears to be an issue with the site")}.
            </Typography>
            <Box p={2} width="100%">
              <p>{t("Please reload to ensure you have the latest version")}.</p>
            </Box>
            <Button variant="contained" color="primary" size="large" onClick={doReload} startIcon={<RefreshIcon />}>
              {t("RELOAD")}
            </Button>
          </Box>
        </Box>
      </motion.div>
    </div>
  );
};

export default ErrorFallback;
