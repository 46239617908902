import React from "react";
import { useSnackbar } from "notistack";
import IconButton from "@material-ui/core/IconButton";
import Clear from "@material-ui/icons/Clear";

const useToast = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const action = (key: any) => (
    <IconButton size="small" onClick={() => closeSnackbar(key)}>
      <Clear style={{ fontSize: "inherit", color: "white" }} />
    </IconButton>
  );

  return {
    error: (msg: string, noAction?: boolean) =>
      enqueueSnackbar(msg, { variant: "error", action: noAction ? null : action }),
    warning: (msg: string, noAction?: boolean) =>
      enqueueSnackbar(msg, { variant: "warning", action: noAction ? null : action }),
    success: (msg: string, noAction?: boolean) =>
      enqueueSnackbar(msg, { variant: "success", action: noAction ? null : action }),
    info: (msg: string, noAction?: boolean) =>
      enqueueSnackbar(msg, { variant: "info", action: noAction ? null : action }),
    default: (msg: string, noAction?: boolean) =>
      enqueueSnackbar(msg, { variant: "default", action: noAction ? null : action }),
  };
};

export default useToast;
