import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import React, { createContext } from "react";

const themeDefaults = {
  fontFamily: ["Muli", "Calibri", "Roboto", '"Helvetica Neue"', "Arial", "sans-serif"],
  palette: {
    primary: {
      light: "rgb(87,195,255)", // 75% lightness on lab version of main, which is 57% color
      main: "rgb(0, 148, 210)",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "rgb(79,220,188)",
      main: "rgb(26,179,148)",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#fff",
    },
  },
};

type ThemeProps = {
  children: any;
};

export const ThemeCtx = createContext<any>(null);

const Theme = ({ children }: ThemeProps) => {
  const theme = createTheme({
    typography: {
      fontFamily: themeDefaults.fontFamily.join(","),
    },
    palette: themeDefaults.palette,
    overrides: {
      //@ts-ignore
      MUIDataTableToolbar: {
        root: {
          alignItems: "flex-start",
          paddingTop: 8,
          minHeight: 64,
        },
      },
    },
  });

  const defaultColors = (index: number, opacity: number) => {
    const _colors = [
      `rgba(0, 178, 227, ${opacity ?? 1})`, //blue
      `rgba(0, 168, 135, ${opacity ?? 1})`, //green
      `rgba(243, 39, 53, ${opacity ?? 1})`, //red
      `rgba(65, 0, 153, ${opacity ?? 1})`, //purple
      `rgb(255, 136,  ${opacity ?? 1})`, //orange
      `rgba(255, 183, 27, ${opacity ?? 1})`, //yellow
      `rgba(0, 105, 147, ${opacity ?? 1})`, //navy
      `rgba(166, 126 , 204, ${opacity ?? 1})`, //purple
      `rgba(149, 216, 241, ${opacity ?? 1})`, //blue
      `rgba(255, 161, 147, ${opacity ?? 1})`, //red
      `rgba(96, 214, 192, ${opacity ?? 1})`, //green
      `rgba(255, 179, 127, ${opacity ?? 1})`, //orange
      `rgba(255, 218, 150, ${opacity ?? 1})`, //yellow
    ];

    return _colors[index % _colors.length];
  };

  return (
    <ThemeCtx.Provider value={{ theme, defaultColors }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeCtx.Provider>
  );
};

export default Theme;
