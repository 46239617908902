import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import useStyles from "./styles";
import AccountSearch from "../../Components/AccountSearch";
import LanguageSelect from "../../locales/LanguageSelect";
import Logo from "../../Components/Logo";

type BannerProps = {
  toggleDrawer: () => void;
};
function Banner({ toggleDrawer }: BannerProps) {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.centeredToolbar}>
        <Logo reverse height={56} />
        <div style={{ display: "flex" }}>
          <AccountSearch />
          <LanguageSelect />
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default Banner;
