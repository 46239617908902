import React, { useState, useEffect, memo, useRef, PropsWithChildren, Dispatch, SetStateAction } from "react";
import { IFarm } from "../types/farm";
import { saveInLocal, getFromLocal } from "../lib/utilities";
import useAuth from "../hooks/useAuth";
import useLanguage from "../hooks/useLanguage";
import LoginInProgress from "../Screens/LoginInProgress";
import useFarmModules from "../api/useFarmModules";
import useToast from "../hooks/useToast";
import LoginNoFarms from "../Screens/LogInNoFarms";
import useFarmIdQuery from "../hooks/useQuery";
import useFarms from "../api/useFarms";

interface Props {}
type TFarmCtx = [IFarm, Dispatch<SetStateAction<IFarm>>];
const defaultFarm = { farmName: "", farmContact: "" };
const defaults: TFarmCtx = [defaultFarm, () => {}];

export const FarmCtx = React.createContext<TFarmCtx>(defaults);

const FarmProvider = (props: PropsWithChildren<Props>) => {
  const farmid = useRef<string | undefined>(useFarmIdQuery().get("farmid")).current;
  const { user } = useAuth();
  const storageKey = `_COWCONNECT_FARMS_${user.email}`;
  const previousFarm = useRef<IFarm>(getFromLocal(storageKey, "json")).current;
  const toast = useToast();
  const [farm, setFarm] = useState<IFarm>(defaultFarm);
  const { t } = useLanguage();
  const { QFarms } = useFarms();
  const { QModules } = useFarmModules();

  useEffect(() => {
    farm && saveInLocal(storageKey, farm, "json");
  }, [farm, storageKey]);

  useEffect(() => {
    if (QFarms.data?.[0]) {
      if (farmid) {
        const _farm = QFarms.data.find((_f) => _f.farmId?.toLowerCase() === farmid?.toLowerCase());
        _farm && setFarm(_farm);
      } else if (previousFarm) {
        const _farm = QFarms.data.find((_f) => _f.farmId === previousFarm.farmId);
        if (_farm) setFarm(_farm);
        else setFarm(QFarms.data?.[0]);
      } else {
        setFarm(QFarms.data?.[0]);
      }
    } else if (!QFarms.isLoading) {
      setFarm({ farmId: undefined, farmName: "", farmContact: "" });
    }
  }, [QFarms.data, QFarms.isLoading, previousFarm, user.email, farmid]);

  useEffect(() => {
    QFarms.error && toast.error(t("Can't find any farm data"));
  }, [QFarms.error, t, toast]);

  return !!farm && QFarms?.data && QFarms?.data.length > 0 && !!farm.farmId ? (
    <FarmCtx.Provider value={[farm, setFarm]}>{props.children}</FarmCtx.Provider>
  ) : QFarms.isLoading || QModules.isLoading ? (
    <LoginInProgress text="Loading farms" />
  ) : (
    <LoginNoFarms />
  );
};

export default memo(FarmProvider);
