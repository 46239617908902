import React from "react";
import Providers from "./Providers";

interface Props {}

const App = (props: Props) => {
  return <Providers />;
};

export default App;
