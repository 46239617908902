import { useMsal, useAccount, useIsAuthenticated } from "@azure/msal-react";
import { PropsWithChildren } from "react";
import { loginRequest } from "./utils/config";
import React from "react";
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { Box, LinearProgress } from "@material-ui/core";
import axios from "lib/axios";
// import AccessToken from "./components/AccessToken";

function AuthenticatedApiWrapper(props: PropsWithChildren<{}>) {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [interceptorsSet, setInterceptorsSet] = React.useState(false);
  const isAuthenticated = useIsAuthenticated();

  const getToken = React.useCallback(() => {
    if (account && isAuthenticated && inProgress === InteractionStatus.None) {
      return instance
        .acquireTokenSilent({
          ...loginRequest,
          account: account,
        })
        .then((response) => {
          return response.accessToken;
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            if (account) {
              instance.acquireTokenRedirect({ ...loginRequest, account });
            }
          }
          console.log(error);
        });
    } else {
      // DO SOMETHING
    }
  }, [account, isAuthenticated, instance, inProgress]);

  React.useEffect(() => {
    const createInterceptor = async () => {
      await instance.handleRedirectPromise();

      axios.interceptors.request.use(
        async (config) => {
          const token = await getToken();
          config.headers.Authorization = token ? `Bearer ${token}` : "";
          return config;
        },
        (error) => Promise.reject(error)
      );

      axios.interceptors.response.use(
        (res) => (res.status >= 200 && res.status < 300 && res.data !== undefined ? res.data : res),
        (error) => Promise.reject(error)
      );
      setInterceptorsSet(true);
    };
    createInterceptor();
  }, [isAuthenticated, getToken, instance]);

  return (
    <>
      {/* <AccessToken /> */}
      {isAuthenticated && interceptorsSet ? (
        props.children
      ) : (
        <Box width="100vw" height="100vh" display="flex" justifyContent="center" alignItems="center">
          <LinearProgress />
        </Box>
      )}
    </>
  );
}

export { AuthenticatedApiWrapper };
