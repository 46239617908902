import Graph_ from "./Graph";
import Home_ from "./Home";
import Ingredients_ from "./Ingredients";
import FeedManagement_ from "./FeedManagement";
import Recipes_ from "./Recipes";
import Pens_ from "./Pens";
import Groups_ from "./Groups";
import Costings_ from "./Costings";
import Tasks_ from "./Tasks";
import Loads_ from "./Loads";
import Silo_ from "./Silo";
import Pit_ from "./Pit";
import Bale_ from "./Bale";
import BulkTank_ from "./BulkTank";
import NorFor_ from "./NorFor";

export const Graph = Graph_;
export const Home = Home_;
export const Loads = Loads_;
export const Ingredients = Ingredients_;
export const Recipes = Recipes_;
export const Pens = Pens_;
export const Groups = Groups_;
export const Costings = Costings_;
export const Tasks = Tasks_;
export const FeedManagement = FeedManagement_;
export const Silo = Silo_;
export const Pit = Pit_;
export const Bale = Bale_;
export const BulkTank = BulkTank_;
export const NorFor = NorFor_;
export { default as LoadIn } from "./LoadIn";
export { default as LoadOut } from "./LoadOut";
export { default as CowConnect } from "./CowConnect";
export { default as Bucket } from "./Bucket";
export { default as Kg } from "./Kg";
export { default as Contractor } from "./Contractor";
export { default as Milk } from "./Milk";
export { default as Tap } from "./Tap";
export { default as Teamviewer } from "./Teamviewer";
