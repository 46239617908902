import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

interface Props {
  color?: string;
  opacity?: number;
  size?: "small" | "large" | "inherit" | "medium";
}

const Teamviewer = ({ color = "#000", opacity = 0.7, size = "medium" }: Props) => {
  return (
    <SvgIcon fontSize={size}>
      <svg width="24" height="24" viewBox="0 0 1024 1024">
        <path
          fill={color}
          opacity={opacity}
          d="m 917.00124,0 -811.2,0 A 107.2,105.8 0 0 0 0.00124066,106.3 l 0,811.4 A 107.2,105.8 0 0 0 106.40124,1024 l 811.2,0 A 107.2,105.8 0 0 0 1024.0012,917.7 l 0,-811.4 A 107.2,105.8 0 0 0 917.00124,0 Z m -405.4,935.4 A 426.2,420.6 0 0 1 88.701241,512 426.2,420.6 0 0 1 511.60124,88.6 a 426.2,420.6 0 0 1 423.2,423.4 426.2,420.6 0 0 1 -423.2,423.4 z"
          id="path87"
        />
        <path
          fill={color}
          opacity={opacity}
          d="m 904.20124,511.99999 -293.5,-135.7 23.58,86.95 -245.2,0 23.58,-86.95 -293.5,135.8 293.7,135.7 -23.58,-86.95 245.2,0 -23.58,86.95 293.3,-135.7"
          id="path89"
        />
      </svg>
    </SvgIcon>
  );
};

export default Teamviewer;
