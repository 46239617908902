import React from "react";
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ExitToApp from "@material-ui/icons/ExitToApp";
import useAuth from "../hooks/useAuth";
import { motion } from "framer-motion";
import useLanguage from "../hooks/useLanguage";
import Logo from "../Components/Logo";

interface Props {}

const LoginNoFarms = (props: Props) => {
  const classes = useStyles();
  const { user, logout } = useAuth();
  const { t } = useLanguage();
  return (
    <div className={classes.root}>
      <motion.div animate={{ y: 200, opacity: 1 }} initial={{ y: 300, opacity: 0 }} transition={{ delay: 1 }}>
        <Box bgcolor="#fffc" display="flex" justifyContent="center">
          <Box p={2} width={400} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
            <Logo />
            <Typography variant="h5" component="div" style={{ paddingBottom: 16, width: "100%", textAlign: "center" }}>
              {t("No farm registered to")} {user.email || t("this user")}
            </Typography>
            <Button fullWidth onClick={logout} variant="contained" color="primary" size="large" endIcon={<ExitToApp />}>
              {t("Logout")}
            </Button>
          </Box>
        </Box>
      </motion.div>
    </div>
  );
};

export default LoginNoFarms;
