import FarmFinancials from "Features/FarmFinancials";
import React, { lazy, PropsWithChildren } from "react";
import { Redirect, Route, RouteProps, Switch } from "react-router-dom";
import useRole from "../api/useRole";
const Dashboard = lazy(() => import("../Features/Dashboard"));
const FarmDetails = lazy(() => import("../Features/FarmDetails"));
const FarmConnections = lazy(() => import("../Features/FarmConnections"));
const FarmManagement = lazy(() => import("../Features/FarmManagement"));
const Modules = lazy(() => import("../Features/Modules"));
const Roles = lazy(() => import("../Features/Roles"));
const Connections = lazy(() => import("../Features/Companies"));
const FarmAccess = lazy(() => import("../Features/FarmAccess"));
const Devices = lazy(() => import("../Features/Devices"));
const UnusedDevices = lazy(() => import("../Features/UnusedDevices"));
const Logout = lazy(() => import("./Auth/Pages/Logout"));

const Routes = () => {
  const { isError, isAdmin } = useRole();
  return (
    <Switch>
      <Route path="/logout">
        <Logout />
      </Route>

      <Route path="/farm/details">
        <FarmDetails />
      </Route>
      <Route path="/farm/connections">
        <FarmConnections />
      </Route>
      <ProtectedRoute path="/farm/access" isError={isError} enabled={isAdmin}>
        <FarmAccess />
      </ProtectedRoute>
      <ProtectedRoute path="/farm/devices" isError={isError} enabled={isAdmin}>
        <Devices />
      </ProtectedRoute>
      <ProtectedRoute path="/farm/management" isError={isError} enabled={isAdmin}>
        <FarmManagement />
      </ProtectedRoute>
      <ProtectedRoute path="/farm/financial" isError={isError} enabled={isAdmin}>
        <FarmFinancials />
      </ProtectedRoute>

      <ProtectedRoute path="/modules" isError={isError} enabled={isAdmin}>
        <Modules />
      </ProtectedRoute>
      <ProtectedRoute path="/roles" isError={isError} enabled={isAdmin}>
        <Roles />
      </ProtectedRoute>
      <ProtectedRoute path="/companies" isError={isError} enabled={isAdmin}>
        <Connections />
      </ProtectedRoute>
      <ProtectedRoute path="/unuseddevices" isError={isError} enabled={isAdmin}>
        <UnusedDevices />
      </ProtectedRoute>
      <Route path="/">
        <Dashboard />
      </Route>
    </Switch>
  );
};

export default Routes;

interface Props extends RouteProps {
  isError: boolean;
  enabled: boolean;
}

const ProtectedRoute = (props: PropsWithChildren<Props>) => {
  return props.isError || !props.enabled ? <Redirect to="/" /> : <Route path={props.path}>{props.children}</Route>;
};
